import { useEffect, useRef } from 'react';
import './App.css';
import Navbar from './components/navbar';
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Link } from 'react-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css';


function App() {

  const scrollRef = useRef(null);

  useEffect(() => {
   AOS.init({
    delay: 100,
    duration: 600
   }) 
  } )

  return (
    <div className="h-full w-full overflow-hidden min-h-screen bg-black text-white">
      <div className="fixed top-0 z-50">
             <Navbar /> 
      </div>
      <div className="flex text-center flex-col items-center justify-center p-8">
        <div className="flex-none relative font-extrabold text-6xl h-screen flex flex-col justify-center items-center">
          <div>Finance shouldn't be so complicated.</div>
          <button>
            <Link activeClass="active" to="section1" spy={true} smooth={'easeInOutCubic'} duration={1000}>
              <div className="flex transform translate-y-32 flex-col items-center">
                <ChevronDownIcon />
                <ChevronDownIcon opacity={0.4} />
                <ChevronDownIcon opacity={0.2} />
              </div>
            </Link>
          </button>

        </div>
        <div id="section1" className="flex flex-col items-center h-screen justify-center">
          <div data-aos="fade-right" ref={scrollRef}  className="flex-none bg-tile space-x-8 space-y-8 p-16 bg-transparent relative items-center justify-center flex flex-col sm:flex-col md:flex-col lg:flex-row lg:w-2/3 w-full">
            <div className="flex-none card bg-mainblue">
              <div className="font-bold text-3xl">METIS</div>
              <div className="card-holder-name opacity-50 font-light">JANE DOE</div>
            </div>
            <div className="flex flex-col text-left space-y-4">
              <div className="font-extrabold text-3xl">Any economic system is only ever as effective as the education and tools available to its people.</div>
              <div className="font-light text-xl">The appropriate tools, knowledge and services to manage your wealth and become better with money have never truly been completely accessible to everyone.</div>
            </div>
          </div>
          <button>
            <Link activeClass="active" to="section2" spy={true} smooth={true} duration={1000}>
              <div className="flex flex-col items-center">
                <ChevronDownIcon w={48} h={48} />
                <ChevronDownIcon opacity={0.4} w={48} h={48}/>
                <ChevronDownIcon opacity={0.2} w={48} h={48}/>
              </div>
            </Link>
          </button>
        </div>
        
        <div id="section2" className="flex-none flex h-screen flex-col  justify-center  space-x-8 space-y-16 lg:w-2/3 w-full">
                <div className="speech" data-aos="fade-right">
                  <div className="font-bold text-3xl lg:text-5xl row-span-2 italic">"Minimum initial investment amounts for hedge funds range from $100,000 to upwards of $2 million." - Forbes</div>
                </div>
                <div className="speech" data-aos="fade-left">
                  <div className="font-bold text-xl lg:text-3xl italic text-right">"The average cost for a financial advisor is £150 per hour, although it can vary from £75 to £350 per hour..." - The Times</div>
                </div>
                <div className="speech" data-aos="fade-right">
                  <div className="font-bold text-2xl lg:text-4xl italic">"[Big investment bank]'s clients may have to pay as much as £350,000 ($455,000) to get the top service package from its equities analysts" - Bloomberg</div>
                </div>
          <button>
            <Link activeClass="active" to="section3" spy={true} smooth={true} duration={1000}>
              <div className="flex  flex-col items-center">
                <ChevronDownIcon w={48} h={48} />
                <ChevronDownIcon opacity={0.4} w={48} h={48}/>
                <ChevronDownIcon opacity={0.2} w={48} h={48}/>
              </div>
            </Link>
          </button>
        </div>
        <div id="section3"  className="flex flex-col items-center h-screen justify-center">
        <div data-aos="fade-left" className="flex-none  bg-tile space-x-8 space-y-8 p-16 bg-transparent relative items-center justify-center flex flex-col sm:flex-col md:flex-col lg:flex-row lg:w-2/3 w-full">
        <img alt="logo" src="https://metis-content.s3.eu-west-2.amazonaws.com/web/scales.svg" height="200px" width="200px"></img>
          <div className="flex flex-col text-left space-y-4">
            <div className="font-extrabold text-3xl">It's clear that if you've already got a lot of money, almost everything is designed for you to make <i>even more</i>.</div>
            <div className="font-light text-xl">Don't worry, we're not delusional. We know that this makes sense, but wouldn't it be great if the everyday person had access to all the incredible tools that banks and full-service brokerages have, but abstracted at different levels for people of all ranges of financial literacy?</div>
          </div>
        </div> 
        <button>
            <Link activeClass="active" to="section4" spy={true} smooth={true} duration={1000}>
              <div className="flex flex-col items-center">
                <ChevronDownIcon w={48} h={48} />
                <ChevronDownIcon opacity={0.4} w={48} h={48}/>
                <ChevronDownIcon opacity={0.2} w={48} h={48}/>
              </div>
            </Link>
          </button> 
      </div>
      <div  id="section4" className="flex flex-col items-center h-screen justify-center">
      <div data-aos="fade-up" className="flex-none  bg-tile space-x-8 space-y-8 p-16 bg-transparent relative items-center justify-center flex flex-col sm:flex-col md:flex-col lg:flex-row lg:w-2/3 w-full">
          <div className="flex flex-col text-center lg:text-left space-y-4">
            <div className="font-extrabold text-3xl lg:text-5xl">That's why we're making METIS.</div>
            <div className="font-light text-xl">A bank, brokerage and collection of exchanges, built as a seamlessly interlinked ecosystem of financial services - designed from the ground up with a focus on sustainability and transparency.</div>
          </div>
          <img alt="logo" src="https://metis-content.s3.eu-west-2.amazonaws.com/web/logo.svg"></img>
      </div> 
      </div>
      <div  id="section4" className="flex flex-col items-center justify-center">
      <div data-aos="fade-up" className="flex-none  bg-tile space-x-8 space-y-8 p-16 bg-transparent relative items-center justify-center flex flex-col sm:flex-col md:flex-col lg:flex-row lg:w-2/3 w-full">
          <div className="flex flex-col lg:flex-row items-baseline text-center lg:text-left space-y-4">
            <div className="font-extrabold text-3xl lg:text-5xl">But we're still under construction.</div>
            <div className="font-light text-xl">We'll soon update this website with more information on all the exciting work we've been doing, and crowdfunding opportunities.</div>
          </div>
      </div> 
      </div>
      </div>
      </div>
  );
}

export default App;
