import * as React from 'react';

const Navbar = () => {
	return (
		<div className="flex justify-center flex-row h-14 bg-transparent w-screen p-2">
			<div className="logo-box flex flex-none h-full w-16 items-center p-2"><img alt="logo" src="https://metis-content.s3.eu-west-2.amazonaws.com/web/logo.svg"></img></div>
		</div>
	)
}

export default Navbar;